<template>
  <a-list item-layout="horizontal" bordered :data-source="items">
    <a-list-item slot="renderItem" slot-scope="item">
      <a-list-item-meta :description="item.code">
        <div class="row my-3 ml-2" slot="title">
          <div class="row">
            <!--Antiguedad-->
            <div class="col-6" >
              <span class="font-weight-bold">Antiguedad: </span>
              <span>{{moment(item.antiquity).format('DD/MM/YY')}} </span>
              |
              <span v-if="moment().diff(moment(item.antiquity), 'days') > 1">
                hace {{ moment().diff(moment(item.antiquity), 'days') }} días
              </span>
              <span v-else>{{moment(item.antiquity).fromNow()}}</span>
              &nbsp;
              <a-icon v-if="moment().diff(moment(item.antiquity), 'days') > 150"
                      type="warning" theme="twoTone" two-tone-color="#f2a654"/>
              <a-icon type="like" theme="twoTone" two-tone-color="#00a654" v-else/>
            </div>
            <!--Material-->
            <div class="col-6" >
              <span class="font-weight-bold">Material: </span>
              <span>{{ item.material_name }}</span>
            </div>
            <!--Material-->
            <div class="col-6" >
              <span class="font-weight-bold">Cantidad: </span>
              <span>{{ item.quantity }}</span>
            </div>
            <!--Posición-->
            <div class="col-6" >
              <span class="font-weight-bold">Posición: </span>
              <span v-if="item.wpositions">{{ item.wpositions.name }}</span>
              <span v-else> NA</span>
            </div>
            <div class="col-6" >
              <span class="font-weight-bold">Contenedor: </span>
              <span>{{ item.last_tdetail.containerTypes.name }}</span>
            </div>
            <!--Estatus y slot de botones-->
            <div class="col-12 mt-3">
              <div class="row">
                <!--Estatus-->
                <div class="col-6">
                  <span class="font-weight-bold">Estatus: </span>
                  <titemStatus :status="item.status"/>
                </div>
                <!--slot de botones-->
                <div class="col-6">
                  <slot name="item-buttons" :item="item"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="avatar">
          <slot name="btn-icon" :item="item"/>
        </template>
      </a-list-item-meta>
    </a-list-item>
  </a-list>
</template>

<script>
import titemStatus from '../components/titemStatus'
export default {
  name: 'titemsResume',
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  components: {
    titemStatus,
  },
}
</script>

<style scoped>

</style>
