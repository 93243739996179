<template>
  <div>
    <a-icon type="check-square" v-if="status === titemsStatus.autorizado"
            theme="twoTone" two-tone-color="#00a654"/>
    <a-icon type="clock-circle" v-else-if="status === titemsStatus.pendiente"
            theme="twoTone" two-tone-color="#f2a654"/>
    <a-icon type="warning" v-else-if="status === titemsStatus.rechazado"
            theme="twoTone" two-tone-color="#ff0000"/>
    <a-icon type="frown" v-else-if="status === titemsStatus.baja"/>
    <a-icon v-else-if="status === titemsStatus.formulacion"
            type="clock-circle" theme="twoTone"/>
    <a-icon type="delete" v-else-if="status === titemsStatus.destruido"
            theme="twoTone" two-tone-color="#ff0000"/>
    {{ status }}
  </div>
</template>

<script>
import { titemsStatusConstant } from '@/constant/titems'
export default {
  name: 'titemStatus',
  props: {
    status: { type: String, required: true },
  },
  data() {
    return {
      titemsStatus: titemsStatusConstant,
    }
  },
}
</script>

<style scoped>

</style>
