<template>
<div>
  <div class="air__utils__heading">
    <h5>Ubicación de elementos </h5>
  </div>
  <my-breadcrumbs :routes="routes" class="my-4"/>
  <div class="row justify-content-center">
    <b-input-group>
      <template #prepend>
        <b-form-select
          disabled
          v-model="searchType"
          :options="searchTypes"
        />
      </template>

      <b-form-input
        disabled
        type="search"
        id="filterInput"
        v-model="search"
        placeholder="Folio"
        @keyup.esc ="clear()"
        @keyup.enter ="FilterData()"
      />

      <template #append>
        <b-input-group-append>
          <b-button
            size="sm"
            @click="clear()"
            variant="default"
            :disabled="!search"
          >
            <b-icon-x/>
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            @click="FilterData()"
            :disabled="!searchType"
          >
            <b-icon-search/>
          </b-button>
        </b-input-group-append>
      </template>
    </b-input-group>
  </div>
  <div class="row mt-4 bg-white py-5" v-if="items.length > 0">
    <div class="col-sm-12 col-md-6">
      <titemsResume :items="items">
        <a-button slot="btn-icon" slot-scope="{item}"  @click="Select(item)">
          <b-icon icon="hand-index-thumb"></b-icon>
        </a-button>
      </titemsResume>
    </div>
    <div class="col-sm-12 col-md-6" v-if="Object.keys(selectedItem).length > 0">
      <div class="container-fluid">
        <div class="row justify-content-center" >
          <a-descriptions bordered size="small" :title="'Elemento: ' + selectedItem.code"
                          :column="{ md: 2, sm: 1 }">
            <a-descriptions-item label="Material">
              <strong>{{selectedItem.material_name}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Antiguedad">
              <span>{{moment(selectedItem.antiquity).format('DD/MM/YY')}} </span>
              |
              <span v-if="moment().diff(moment(selectedItem.antiquity), 'days') > 1">
              hace {{ moment().diff(moment(selectedItem.antiquity), 'days') }} días
            </span>
              <span v-else>{{moment(selectedItem.antiquity).fromNow()}}</span>
              &nbsp;|
              <a-icon v-if="moment().diff(moment(selectedItem.antiquity), 'days') > 150"
                      type="warning" theme="twoTone" two-tone-color="#f2a654"/>
              <a-icon type="like" theme="twoTone" two-tone-color="#00a654" v-else/>
            </a-descriptions-item>
            <a-descriptions-item label="Estatus">
              <titemStatus :status="selectedItem.status"/>
            </a-descriptions-item>
            <a-descriptions-item label="Posición">
              <strong v-if="selectedItem.wpositions">{{ selectedItem.wpositions.name }}</strong>
              <span v-else> NA</span>
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div class="row justify-content-center mt-5">
          <a-timeline mode="alternate">
            <a-timeline-item v-for="tdetail in selectedItem.tdetails" :key="tdetail.id" :color="color(tdetail.transactions.warehouseTransactions.name)">
              <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
              <b-container fluid>
                <div class="row">
                  <div class="col-12">
                    <strong class="font-size-14 text-real-blue"> {{tdetail.transactions.code}}</strong>
                  </div>
                  <div class="col-6">
                    <strong class="font-size-14">Tipo:</strong>
                  </div>
                  <div class="col-6">
                    <span class="font-size-12"> {{tdetail.transactions.warehouseTransactions.name}}</span>
                  </div>
                  <div class="col-6">
                    <strong class="font-size-14">Almacén:</strong>
                  </div>
                  <div class="col-6">
                    <span class="font-size-12"> {{tdetail.transactions.warehouses.name}}</span>
                  </div>
                  <div class="col-6">
                    <strong class="font-size-14">Fecha:</strong>
                  </div>
                  <div class="col-6">
                    <span class="font-size-12"> {{moment(tdetail.transactions.dated_at).format('DD/MM/YY HH:mm')}}</span>
                  </div>
                  <div class="col-6">
                    <strong class="font-size-14">Contenedores:</strong>
                  </div>
                  <div class="col-6">
                    <span class="font-size-12"> {{tdetail.container_quantity}}</span> <strong>{{tdetail.containerTypes.name}}</strong>
                  </div>
                  <div class="col-6">
                    <strong class="font-size-14">Cantidad Real:</strong>
                  </div>
                  <div class="col-6">
                    <span class="font-size-12"> {{tdetail.real_quantity}}</span> <strong>{{tdetail.ums.name}}</strong>
                  </div>
                </div>
              </b-container>
            </a-timeline-item>
          </a-timeline>
        </div>
      </div>
    </div>
  </div>
  <my-spinner :load="loadItems" v-else/>
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import { titemsStatusConstant } from '@/constant/titems'
import titemsResume from '../components/titemsResume'
import titemStatus from '../components/titemStatus'
export default {
  name: 'item_history',
  mixins: [fractalMixin],
  components: {
    titemsResume,
    titemStatus,
  },
  data() {
    return {
      titemsStatus: titemsStatusConstant,
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          aIcon: '',
          name: 'operations',
        },
        {
          breadcrumbName: 'Menú de Almacén',
          aIcon: '',
          name: 'warehouse_menu',
        },
        {
          breadcrumbName: 'Gestión de Almacenes',
          aIcon: '',
          name: 'warehouses',
        },
        {
          breadcrumbName: 'Historial de Elementos',
          aIcon: '',
        },
      ],
      searchType: 'transaction',
      searchTypes: [
        // { value: 'titem', text: 'Elemento' },
        { value: 'transaction', text: 'Transacción' },
      ],
      search: '',
      items: [],
      selectedItem: {},
      loadItems: false,
    }
  },
  methods: {
    clear() {
      this.search = ''
    },
    async FilterData() {
      const params = {
        include: 'tdetails.transactions.warehouses,tdetails.transactions.warehouseTransactions,tdetails.transactions,tdetails.containerTypes,tdetails.ums,wpositions',
        // 'fields[tdetails]': 'code,status,material_name,antiquity,transactions,containerTypes,ums',
        'fields[transactions]': 'code,dated_at,warehouseTransactions,warehouses',
        'fields[containerTypes]': 'name',
        'fields[ums]': 'name',
        'fields[wpositions]': 'name',
        'fields[warehouseTransactions]': 'name',
        'fields[warehouses]': 'name',
      }

      if (this.searchType === 'titem') {
        params['filter[code]'] = this.search
      } else {
        params['filter[transactionCode]'] = this.search
      }
      this.items = await this.GetResource('/titems', params)
      this.selectedItem = {}
    },
    Select(item) {
      this.selectedItem = item
    },
    color(warehouseTransactionsName) {
      if (warehouseTransactionsName === 'Entrada a Almacén' || warehouseTransactionsName === 'Entrada por Traspaso') {
        return 'green'
      } else if (warehouseTransactionsName === 'Salida de Almacén' || warehouseTransactionsName === 'Salida por Traspaso') {
        return 'red'
      } else return 'blue'
    },
  },
  async mounted() {
    this.loadItems = true
    this.search = this.$route.params.transactionCode
    await this.FilterData()
    this.loadItems = false
  },
}
</script>

<style scoped>

</style>
